@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

/* Sections */
.hidden{
  display: none;
}

.item {
    width: 100%;
    padding: 50px;
    height: 100vh;
    text-align: center;
    /* background-color: #ecf0f1; */
    background-color: rgb(28, 30, 32);
    border-bottom: 1px solid #ccc;
    scroll-margin-top: 100px;
    color:#f8eeee
  }
  
  .col-full {
    width: 1044px;
  }

  .first{
    align-content: center;
  }

  /* Home */

  .home-btn{
    font-size: 1.70em;
    cursor: pointer;
    padding: 2%;
    border-radius: 50%;
    border: 3px solid rgb(139, 32, 32);
    /* opacity: .2; */
    /* color: #f8eeee; */
  }

  .home-btn-div-inner{
    
    /* border: 3px solid rgb(139, 32, 32); */
    width: 25%;
    margin: auto;
    cursor: pointer;
    padding: 3%;
    font-size: 1.7em;
    font-family: 'Roboto Mono';
    border: .1em solid white;
    background-color: #070809;
  }
  .home-btn-div-outer{
    margin: 3%;
  }

  .home-text {
    font-size: 2em;
    font-family: 'Roboto Mono';
  }

  /* Section Animations */
  .active-scroll-from-left{
    position: relative;
    animation-name: scroll-from-left;
    animation-duration: .75s;
    animation-timing-function: linear;
    animation-direction: alternate;
  }
  .active-scroll-from-right{
    position: relative;
    animation-name: scroll-from-right;
    animation-duration: .75s;
    animation-timing-function: linear;
    animation-direction: alternate;
  }

  .active-scroll-from-bottom {
    position: relative;
    animation-name: scroll-from-bottom;
    animation-duration: .75s;
    animation-timing-function: linear;
    animation-direction: alternate;
  }

  @keyframes scroll-from-left {
    0%   { left:-800px; top:0px;}
    25%  { left:-600px; top:0px;}
    50%  {left:-400px; top:0px;}
    75%  { left:-200px; top:0px;}
    100% {left:0px; top:0px;}
  }

  @keyframes scroll-from-right {
    0%   { right:-800px; top:0px;}
    25%  { right:-600px; top:0px;}
    50%  {right:-400px; top:0px;}
    75%  { right:-200px; top:0px;}
    100% {right:0px; top:0px;}
  }

  @keyframes scroll-from-bottom {
    0%   { left:0px; bottom:-400px;}
    25%  { left:0px; bottom:-300px;}
    50%  {left:0px; bottom:-200px;}
    75%  { left:0px; bottom:-100px;}
    100% {left:0px; bottom:0px;}
  }

  /* About */

  .about-left{
    /* border: 3px solid white; */
    display: inline-block;
    width: 40%;
    height: 60%;
    margin: 1%;
  }

  .about-right{
    /* border: 3px solid white; */
    display: inline-block;
    width: 50%;
    height: 60%;
    margin: 1%;
    float: right;
  }

  .code-image{
    height: 100%;
    width: 100%;
    display: block;
    padding: 0;
    /* border: 3px solid white; */
    /* margin: 2%; */
  }

  .about-text{
    font-size: 1.2em;
    margin: 1%;
    font-family: 'Roboto Mono';
    text-align: left;
    /* margin-top: 3%; */
  }

  .resume-link {
    /* text-decoration: none; */
    font-family: 'Roboto Mono';
    /* border: 3px solid white; */

  }

  /* Projects */

  .projects-title{
    font-family: 'Roboto Mono';
    margin-bottom: 2%;
  }

  .app-title{
    font-family: 'Roboto Mono';
    
  }

  .app-link{
    text-decoration: none;
    /* border: 1px solid white; */
  }

  .project-div{
    /* border: 2px solid white; */
    display: inline-block;
  }

  /* Contact */

  .contact-title{
    /* border: 1px solid red; */
    font-family: 'Roboto Mono';
    margin-bottom: 2%;

  }

  .email-icon{
    /* background-color: white; */
    width: 10%;
    height: 10%;
    vertical-align: middle;
    margin: 2%;
  }

  .email-span{
    border: 1px solid white;
    height: 100%;
  }
  .contact-div{
    /* border: 1px solid red; */
    height: 25%;
    background-color: white;
    width: 30%;
    margin: auto;
    color: black;
    font-family: 'Roboto Mono';
    text-align: left;
    padding-left: 5%;
  }

  #sec-4.item{
    background-color: white;
    color: black;
    border-bottom: none;
  }

  .contact-p{
    display: inline;
    vertical-align:middle;
  }

  .footer{
    text-align: center;
    font-size: .8em;
  }

  /* Mobile Friendly */

  @media screen and (max-width: 1187px){
    .contact-div{
      height: 80%;
      width: 35%;
      padding-left: 6%;
    }

    .about-left{
      /* border: 3px solid white; */
      display: inline-block;
      width: 100%;
      /* height: 60%; */
      height: auto;
      margin: 0%;
    }

    .about-right{
      /* display: none; */
      /* border: 3px solid white; */
      margin-top: auto;
      width: 100%;
    }

    .about-sec{
      height: 150vh;
      text-align: center;
    }

    .code-image{
      height: 50%;
      width: 50%;
      /* display: block; */
      /* padding: 0; */
      margin: auto;
      /* border: 3px solid white; */
      /* margin: 2%; */
    }
  }
  @media screen and (max-width: 1038px){
    .contact-div{
      height: 80%;
      width: 35%;
      padding-left: 2%;
    }
  }

  @media screen and (max-width: 933px){
    .contact-div{
      width: 45%;
    }
  }

  @media screen and (max-width: 825px){
    .contact-div{
      height: 80%;
      width: 60%;
      padding: 3%;
    }

    .home-btn-div-inner{
      width: 50%;
    }

    .active-scroll-from-left.about-left{
      /* border: 1px solid white; */
      position: relative;
      animation-name:none;
      animation-duration: .75s;
      animation-timing-function: linear;
      animation-direction: alternate;
    }
  }


  @media screen and (max-width: 615px){
    /* Home */
    .home-btn-div-inner{    
      width: 50%;
      padding: 3%;
    }

    .about-right{
      /* border: 3px solid white; */
      display: block;
      width: 100%;
      height: 40%;
      /* margin: 1%;
      float: right; */
      margin-top: 5%;
    }

    .code-image{
      width: 80%;
    }


    .contact-div{
      height: 15%;
      width: 100%;
      padding-left: 15%;
    }
  }

  @media screen and (max-width: 450px){
    .contact-div{
      padding-left: 5%;
    }

    .about-left{
      padding: 0;
      margin: 0;
    }

    .about-right{
      display: none;
    }
  }

  