/* Normalize */
* {
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    padding-bottom: 560px;
    /* background-color: rgb(28, 30, 32); */
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    margin: 0;
  }
  
  a {
    color: inherit;
  }
  
  
  