/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

nav {
    top: 0%;
    width: 100%;
    position: sticky;
    background-color: #070809;
    padding: 1.1%;
    border-bottom: 1px solid white;
    z-index: 1000;
  }
  
  .container {
    padding: 22px;
    margin: 0 auto;
    text-align: center;
    
  }
  
  .container ul li {
    list-style: none;
    margin: 0 10px;
    display: inline-block;
  }
  
  .container ul li a:hover {
    padding: 12px;
    color: #fc0;
  }
  
  .container ul li a {
    margin: 8px;
    padding: 10px;
    color: #fff;
    font-size: 1.2em;
    text-decoration: none;
    transition: all 0.3s ease-out;
  }
  
  .active {
    color: white;
    background: none;
    font-size: 1.70em;
    font-family: 'Roboto Mono';
    border: none;
    /* border-bottom: 2px solid #d0d7d9; */
    /* border-bottom: 2px solid rgb(139, 32, 32); */
    border-bottom: 2px solid rgb(211, 56, 56);
    cursor: pointer;
  }
  
  .container p {
    margin-bottom: 16px;
    color: #fc0;
    font-weight: 700;
    font-size: 20px;
  }

  /* Nav Link Buttons */

  .btn-li{
    padding-right: 1%;
  }

  .nav-btn {
    color: white;
    background: none;
    border: none;
    font-size: 1.70em;
    font-family: 'Roboto Mono';
    /* font-family: "Playfair Display"; */
    
  }

  .nav-btn:hover{
    cursor: pointer;
    color: rgb(232, 222, 222);
  }